body,
#root,
#root > div,
.phoneNumber {
    height: 100vh;
}

.project-explanation {
    width: 60vw;
    margin: auto;
}

.project-explanation form {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 450px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #eee;
}

@media screen and (max-width: 768px) {
    .project-explanation,
    .project-explanation form {
        margin: 16px;
        width: auto;
    }
}

.project-explanation form label {
    cursor: pointer;
}

.choices-option {
    margin-top: 8px;
}

.phoneNumber {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.phoneNumber form {
    width: 250px;
}

form {
    display: flex;
    flex-direction: column;
}

.phoneNumber input,
input[type="submit"] {
    margin-top: 8px;
    height: 28px;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
}

.red {
    color: #fb4e4e;
}

.center {
    text-align: center;
}

.buyuu {
    font-size: 24px;
}

.lilliputLand {
    font-size: 12px;
}

.background-green {
    background: #24c25e95;
}

.background-blue {
    background: #0069ff95;
}

.background-orange {
    background: orange;
}

.background-grey {
    background: pink;
}

input[type="submit"] {
    padding: 8px;
    height: auto;
    background: #1a1a1a;
    color: #fff;
    font-size: 16px;
}

input[type="submit"]:hover {
    cursor: pointer;
}

.error {
    font-size: 12px;
    color: #fb4e4e;
    top: 100%;
}

.question {
    width: 60vw;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .question {
        width: 100%;
    }

    .circles > *,
    input[type="submit"] {
        margin: 16px;
    }
}

.circles {
    cursor: grab;
}

.circles > div {
    background: #eee;
    border-radius: 4px;
}

.drag-active {
    cursor: grabbing;
}

.son-sayfa {
    display: flex;
    flex-direction: column;
    align-items: center;
}